import Button, { ButtonProps } from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import React, { AnchorHTMLAttributes } from 'react';

const useStyles = makeStyles((theme) => ({
  button: {
    '&.MuiButton-root': {
      padding: '0 16px',
      height: 36.5,
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: 1.75,
      fontSmooth: 'never',
      fontFamily: '"Open Sans", Muli, Roboto, Helvetica, Arial, sans-serif',
    },
    '&.MuiButton-outlinedSecondary': {
      border: `1px solid ${fade(theme.palette.primary.main, 0.5)}`,
    },
    '&.MuiButton-outlinedSecondary.Mui-disabled': {
      background: 'transparent', // 'rgba(0, 0, 0, 0.12)',
      // opacity: 0.87,
      borderColor: 'rgba(0, 0, 0, 0.26)', // 'transparent'
    },
    '&.MuiButton-outlinedSecondary:hover': {
      border: '1px solid #a48adb',
      background: '#a48adb',
      color: 'white',
    },
    '&.MuiButton-containedSecondary': {
      // border: `1px solid ${fade(theme.palette.secondary.main, 0.5)}`
    },
    '&.MuiButton-containedSecondary.Mui-disabled': {
      opacity: 0.87,
      // borderColor: 'transparent'
    },
    '&.MuiButton-containedSecondary:hover': {
      // border: '1px solid #a48adb',
      background: '#a48adb',
    },
  },
}));

interface Props extends ButtonProps {
  btnText: any | undefined;
  btnType?: 'primary' | 'secondary';
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  component?: keyof JSX.IntrinsicElements;
  download?: AnchorHTMLAttributes<HTMLAnchorElement>['download'];
}

function StandardButton(props: Props) {
  const classes = useStyles();

  return (
    <Button
      className={`${props.className} ${classes.button}`}
      color="primary"
      variant={props.btnType === 'secondary' ? 'outlined' : 'contained'}
      onClick={(e: any) => {
        if (props.onClick) {
          e.preventDefault();
          props.onClick(e);
        }
      }}
      // autoFocus={props.btnType !== 'secondary'}
      startIcon={props.leftIcon}
      endIcon={props.rightIcon}
      disabled={props.disabled}
      style={props.style}
      component={props.component || 'button'}
      {...props}
    >
      {props.btnText}
    </Button>
  );
}

export default StandardButton;
